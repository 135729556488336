// Custom Seafood Innovation site CSS.

// Variables
$csi-primary: #1C7BBB;
$csi-secondary: #0A838E;
$csi-secondary-light: #12a79d;
$csi-secondary-faint: #e6f4f4;
$csi-tertiary: #003B5C;

.csi-site {
  // Styles go here.

  h1.title {
    color: $csi-tertiary;
  }

  // COLOR CLASSES & OVERRIDES. For overriding section backgrounds with a class in the ui.
  h2, h3, h4, h5 {
    color: $csi-secondary;
  }

  .background-color-primary, .page-section.background-color-primary {
    background-color: $csi-primary;
    color: white;

    h1, h2, h3, h4, h5 {
      color: white;
    }
  }

  .background-color-secondary, .page-section.background-color-secondary {
    background-color: $csi-secondary;
    color: white;

    h1, h2, h3, h4, h5 {
      color: white;
    }
  }

  .background-color-faint, .page-section.background-color-faint {
    background-color: $csi-secondary-faint;
    color: black;

    h1 {

    }

    h2, h3, h4, h5 {
      color: $csi-tertiary;
    }
  }

  .background-color-tertiary, .page-section.background-color-tertiary {
    background-color: $csi-tertiary;
  }

  .page-section.page-section--color-3 h1, .page-section.page-section--color-3 h2,
  .page-section.page-section--color-3 h3, .page-section.page-section--color-3 h4,
  .page-section.page-section--color-3 h5, .page-section.page-section--color-3 h6 {
    color: $csi-secondary;
  }

  // HEADER.
  // Remove blue border.

  &.not-logged-in {
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0;
    @include breakpoint('lg') {
      padding-top: 61px;
    }

    &:before {
      border-left-width: 0;
      border-bottom-width: 0;
      border-right-width: 0;
      @include breakpoint('lg') {
        border-top-width: 61px;
      }
    }
  }

  @include breakpoint('lg') {
    .viu-audience-nav {
      padding: 18px 0;
      height: 61px;
    }
    .viu-audience-nav__list li a {
      font-size: 25px;
      line-height: 25px;
      padding: 0 5em
    }
  }

  .homepageHero__scroll {
    display: none;
  }

  .page-section--hero .page-section__content__inner {
    @include make-xs-column(8);
    @include make-xs-column-offset(2);
    position: static;
  }

  .csi_logo {
    text-align: left;
    font-size: 1.8em;
    float: right;
    @include breakpoint('sm') {
      font-size: 60px;
      line-height: 1em;
    }
    @include breakpoint('md') {
      font-size: 84px;
    }
  }

  .csi_logo__small {
    text-transform: uppercase;
    font-size: 1.2rem;
    line-height: 1.2rem;
    font-weight: normal;
    letter-spacing: .1em;
    @include breakpoint('sm') {
      font-size: 25px;
      line-height: .8em;
    }
    @include breakpoint('md') {
      font-size: 38px;
    }
  }

  // SIDE NAV.
  .side-apply-tab, .side-search-tab {
    background: white;
    color: $csi-primary;
  }

  // hide main menu.
  .viuNavigation__toggle-menu, #nav-viu-main {
    display: none;
  }

  .viuNavigation__menu-header {
    display: none;
  }

  .nav__csi_logo {
    @include nav_contained;
    color: white;
    font-size: 1.5em;
    line-height: 1em;
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 40px;
    a, a:hover{
      text-decoration: none;
      color:white;
    }
  }

  .nav__csi_logo__small {
    font-size: .5em;
    line-height: 1em;
    font-weight: normal;
  }

  .globalCTAs__cta--has-image {
    text-align: left;

  }

  .globalCTAs__link {
    margin-left: 0;
    margin-right: 0;
  }

  .panel-pane.pane-page-content, .panel-pane {
    padding-bottom: 0;
  }

  .viu-social a[href*="//"]:not([href*="viu.ca"]):after, #nav-site a[href*="//"]:not([href*="viu.ca"]):after {
    display: none;
  }

  // Theme overrides.
  .page-section.page-section--color-1 a.cta-button, .box-color-1 a.cta-button {
    color: #0a838e;
    background-color: white;
    border-color: white;
  }
  .page-section.page-section--color-1 a.cta-button:hover, .box-color-1 a.cta-button:hover {
    color: white;
    background-color: transparent;
    border-color: white;
  }

}

.csiFooter {
  border-top-color: $csi-secondary;
  border-top-style: solid;
  border-top-width: 61px;

  .viuFooter__linkList__heading, .footer__linkList__a, .viuFooter__contact__heading, address {
    color: $csi-tertiary !important;
  }

  .footer__csi_logo {
    font-size: 2.1em;
    line-height: 1em;
    font-weight: bold;
    text-transform: uppercase;
  }

  .footer__csi_logo__small {
    font-size: 1em;
    font-weight: bold;
    text-transform: uppercase;
  }

  .footer__csi_button a {
    display: inline-block;
    color: white;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 0px;
  }
}
