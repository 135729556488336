/* line 10, bower_components/bootstrap-sass/assets/stylesheets/bootstrap/_grid.scss */
.container {
  margin-right: auto;
  margin-left: auto;
  padding-left: 4px;
  padding-right: 4px;
}

@media (min-width: 768px) {
  /* line 10, bower_components/bootstrap-sass/assets/stylesheets/bootstrap/_grid.scss */
  .container {
    padding-left: 5px;
    padding-right: 5px;
  }
}

@media (min-width: 992px) {
  /* line 10, bower_components/bootstrap-sass/assets/stylesheets/bootstrap/_grid.scss */
  .container {
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media (min-width: 1200px) {
  /* line 10, bower_components/bootstrap-sass/assets/stylesheets/bootstrap/_grid.scss */
  .container {
    padding-left: 15px;
    padding-right: 15px;
  }
}

/* line 14, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_clearfix.scss */
.container:before, .container:after {
  content: " ";
  display: table;
}

/* line 19, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_clearfix.scss */
.container:after {
  clear: both;
}

@media (min-width: 768px) {
  /* line 10, bower_components/bootstrap-sass/assets/stylesheets/bootstrap/_grid.scss */
  .container {
    width: 728px;
  }
}

@media (min-width: 992px) {
  /* line 10, bower_components/bootstrap-sass/assets/stylesheets/bootstrap/_grid.scss */
  .container {
    width: 948px;
  }
}

@media (min-width: 1200px) {
  /* line 10, bower_components/bootstrap-sass/assets/stylesheets/bootstrap/_grid.scss */
  .container {
    width: 1148px;
  }
}

/* line 30, bower_components/bootstrap-sass/assets/stylesheets/bootstrap/_grid.scss */
.container-fluid {
  margin-right: auto;
  margin-left: auto;
  padding-left: 4px;
  padding-right: 4px;
}

@media (min-width: 768px) {
  /* line 30, bower_components/bootstrap-sass/assets/stylesheets/bootstrap/_grid.scss */
  .container-fluid {
    padding-left: 5px;
    padding-right: 5px;
  }
}

@media (min-width: 992px) {
  /* line 30, bower_components/bootstrap-sass/assets/stylesheets/bootstrap/_grid.scss */
  .container-fluid {
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media (min-width: 1200px) {
  /* line 30, bower_components/bootstrap-sass/assets/stylesheets/bootstrap/_grid.scss */
  .container-fluid {
    padding-left: 15px;
    padding-right: 15px;
  }
}

/* line 14, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_clearfix.scss */
.container-fluid:before, .container-fluid:after {
  content: " ";
  display: table;
}

/* line 19, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_clearfix.scss */
.container-fluid:after {
  clear: both;
}

/* line 39, bower_components/bootstrap-sass/assets/stylesheets/bootstrap/_grid.scss */
.row {
  margin-left: -4px;
  margin-right: -4px;
}

/* line 14, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_clearfix.scss */
.row:before, .row:after {
  content: " ";
  display: table;
}

/* line 19, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_clearfix.scss */
.row:after {
  clear: both;
}

@media (min-width: 768px) {
  /* line 39, bower_components/bootstrap-sass/assets/stylesheets/bootstrap/_grid.scss */
  .row {
    margin-left: -5px;
    margin-right: -5px;
  }
}

@media (min-width: 992px) {
  /* line 39, bower_components/bootstrap-sass/assets/stylesheets/bootstrap/_grid.scss */
  .row {
    margin-left: -10px;
    margin-right: -10px;
  }
}

@media (min-width: 1200px) {
  /* line 39, bower_components/bootstrap-sass/assets/stylesheets/bootstrap/_grid.scss */
  .row {
    margin-left: -15px;
    margin-right: -15px;
  }
}

/* line 16, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/scss/boostrap_overrides/mixins/_grid-framework.scss */
.col-xs-1, .col-sm-1, .col-md-1, .col-lg-1, .col-xs-2, .col-sm-2, .col-md-2, .col-lg-2, .col-xs-3, .col-sm-3, .col-md-3, .col-lg-3, .col-xs-4, .col-sm-4, .col-md-4, .col-lg-4, .col-xs-5, .col-sm-5, .col-md-5, .col-lg-5, .col-xs-6, .col-sm-6, .col-md-6, .col-lg-6, .col-xs-7, .col-sm-7, .col-md-7, .col-lg-7, .col-xs-8, .col-sm-8, .col-md-8, .col-lg-8, .col-xs-9, .col-sm-9, .col-md-9, .col-lg-9, .col-xs-10, .col-sm-10, .col-md-10, .col-lg-10, .col-xs-11, .col-sm-11, .col-md-11, .col-lg-11, .col-xs-12, .col-sm-12, .col-md-12, .col-lg-12 {
  position: relative;
  min-height: 1px;
  padding-left: 4px;
  padding-right: 4px;
}

@media (min-width: 768px) {
  /* line 16, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/scss/boostrap_overrides/mixins/_grid-framework.scss */
  .col-xs-1, .col-sm-1, .col-md-1, .col-lg-1, .col-xs-2, .col-sm-2, .col-md-2, .col-lg-2, .col-xs-3, .col-sm-3, .col-md-3, .col-lg-3, .col-xs-4, .col-sm-4, .col-md-4, .col-lg-4, .col-xs-5, .col-sm-5, .col-md-5, .col-lg-5, .col-xs-6, .col-sm-6, .col-md-6, .col-lg-6, .col-xs-7, .col-sm-7, .col-md-7, .col-lg-7, .col-xs-8, .col-sm-8, .col-md-8, .col-lg-8, .col-xs-9, .col-sm-9, .col-md-9, .col-lg-9, .col-xs-10, .col-sm-10, .col-md-10, .col-lg-10, .col-xs-11, .col-sm-11, .col-md-11, .col-lg-11, .col-xs-12, .col-sm-12, .col-md-12, .col-lg-12 {
    padding-left: 5px;
    padding-right: 5px;
  }
}

@media (min-width: 992px) {
  /* line 16, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/scss/boostrap_overrides/mixins/_grid-framework.scss */
  .col-xs-1, .col-sm-1, .col-md-1, .col-lg-1, .col-xs-2, .col-sm-2, .col-md-2, .col-lg-2, .col-xs-3, .col-sm-3, .col-md-3, .col-lg-3, .col-xs-4, .col-sm-4, .col-md-4, .col-lg-4, .col-xs-5, .col-sm-5, .col-md-5, .col-lg-5, .col-xs-6, .col-sm-6, .col-md-6, .col-lg-6, .col-xs-7, .col-sm-7, .col-md-7, .col-lg-7, .col-xs-8, .col-sm-8, .col-md-8, .col-lg-8, .col-xs-9, .col-sm-9, .col-md-9, .col-lg-9, .col-xs-10, .col-sm-10, .col-md-10, .col-lg-10, .col-xs-11, .col-sm-11, .col-md-11, .col-lg-11, .col-xs-12, .col-sm-12, .col-md-12, .col-lg-12 {
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media (min-width: 1200px) {
  /* line 16, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/scss/boostrap_overrides/mixins/_grid-framework.scss */
  .col-xs-1, .col-sm-1, .col-md-1, .col-lg-1, .col-xs-2, .col-sm-2, .col-md-2, .col-lg-2, .col-xs-3, .col-sm-3, .col-md-3, .col-lg-3, .col-xs-4, .col-sm-4, .col-md-4, .col-lg-4, .col-xs-5, .col-sm-5, .col-md-5, .col-lg-5, .col-xs-6, .col-sm-6, .col-md-6, .col-lg-6, .col-xs-7, .col-sm-7, .col-md-7, .col-lg-7, .col-xs-8, .col-sm-8, .col-md-8, .col-lg-8, .col-xs-9, .col-sm-9, .col-md-9, .col-lg-9, .col-xs-10, .col-sm-10, .col-md-10, .col-lg-10, .col-xs-11, .col-sm-11, .col-md-11, .col-lg-11, .col-xs-12, .col-sm-12, .col-md-12, .col-lg-12 {
    padding-left: 15px;
    padding-right: 15px;
  }
}

/* line 27, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-1, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9, .col-xs-10, .col-xs-11, .col-xs-12 {
  float: left;
}

/* line 35, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-1 {
  width: 8.33333%;
}

/* line 35, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-2 {
  width: 16.66667%;
}

/* line 35, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-3 {
  width: 25%;
}

/* line 35, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-4 {
  width: 33.33333%;
}

/* line 35, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-5 {
  width: 41.66667%;
}

/* line 35, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-6 {
  width: 50%;
}

/* line 35, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-7 {
  width: 58.33333%;
}

/* line 35, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-8 {
  width: 66.66667%;
}

/* line 35, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-9 {
  width: 75%;
}

/* line 35, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-10 {
  width: 83.33333%;
}

/* line 35, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-11 {
  width: 91.66667%;
}

/* line 35, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-12 {
  width: 100%;
}

/* line 55, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-pull-0 {
  right: auto;
}

/* line 50, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-pull-1 {
  right: 8.33333%;
}

/* line 50, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-pull-2 {
  right: 16.66667%;
}

/* line 50, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-pull-3 {
  right: 25%;
}

/* line 50, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-pull-4 {
  right: 33.33333%;
}

/* line 50, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-pull-5 {
  right: 41.66667%;
}

/* line 50, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-pull-6 {
  right: 50%;
}

/* line 50, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-pull-7 {
  right: 58.33333%;
}

/* line 50, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-pull-8 {
  right: 66.66667%;
}

/* line 50, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-pull-9 {
  right: 75%;
}

/* line 50, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-pull-10 {
  right: 83.33333%;
}

/* line 50, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-pull-11 {
  right: 91.66667%;
}

/* line 50, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-pull-12 {
  right: 100%;
}

/* line 45, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-push-0 {
  left: auto;
}

/* line 40, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-push-1 {
  left: 8.33333%;
}

/* line 40, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-push-2 {
  left: 16.66667%;
}

/* line 40, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-push-3 {
  left: 25%;
}

/* line 40, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-push-4 {
  left: 33.33333%;
}

/* line 40, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-push-5 {
  left: 41.66667%;
}

/* line 40, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-push-6 {
  left: 50%;
}

/* line 40, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-push-7 {
  left: 58.33333%;
}

/* line 40, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-push-8 {
  left: 66.66667%;
}

/* line 40, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-push-9 {
  left: 75%;
}

/* line 40, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-push-10 {
  left: 83.33333%;
}

/* line 40, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-push-11 {
  left: 91.66667%;
}

/* line 40, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-push-12 {
  left: 100%;
}

/* line 60, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-offset-0 {
  margin-left: 0%;
}

/* line 60, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-offset-1 {
  margin-left: 8.33333%;
}

/* line 60, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-offset-2 {
  margin-left: 16.66667%;
}

/* line 60, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-offset-3 {
  margin-left: 25%;
}

/* line 60, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-offset-4 {
  margin-left: 33.33333%;
}

/* line 60, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-offset-5 {
  margin-left: 41.66667%;
}

/* line 60, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-offset-6 {
  margin-left: 50%;
}

/* line 60, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-offset-7 {
  margin-left: 58.33333%;
}

/* line 60, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-offset-8 {
  margin-left: 66.66667%;
}

/* line 60, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-offset-9 {
  margin-left: 75%;
}

/* line 60, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-offset-10 {
  margin-left: 83.33333%;
}

/* line 60, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-offset-11 {
  margin-left: 91.66667%;
}

/* line 60, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-offset-12 {
  margin-left: 100%;
}

@media (min-width: 768px) {
  /* line 27, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12 {
    float: left;
  }
  /* line 35, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-1 {
    width: 8.33333%;
  }
  /* line 35, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-2 {
    width: 16.66667%;
  }
  /* line 35, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-3 {
    width: 25%;
  }
  /* line 35, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-4 {
    width: 33.33333%;
  }
  /* line 35, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-5 {
    width: 41.66667%;
  }
  /* line 35, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-6 {
    width: 50%;
  }
  /* line 35, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-7 {
    width: 58.33333%;
  }
  /* line 35, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-8 {
    width: 66.66667%;
  }
  /* line 35, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-9 {
    width: 75%;
  }
  /* line 35, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-10 {
    width: 83.33333%;
  }
  /* line 35, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-11 {
    width: 91.66667%;
  }
  /* line 35, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-12 {
    width: 100%;
  }
  /* line 55, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-pull-0 {
    right: auto;
  }
  /* line 50, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-pull-1 {
    right: 8.33333%;
  }
  /* line 50, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-pull-2 {
    right: 16.66667%;
  }
  /* line 50, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-pull-3 {
    right: 25%;
  }
  /* line 50, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-pull-4 {
    right: 33.33333%;
  }
  /* line 50, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-pull-5 {
    right: 41.66667%;
  }
  /* line 50, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-pull-6 {
    right: 50%;
  }
  /* line 50, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-pull-7 {
    right: 58.33333%;
  }
  /* line 50, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-pull-8 {
    right: 66.66667%;
  }
  /* line 50, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-pull-9 {
    right: 75%;
  }
  /* line 50, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-pull-10 {
    right: 83.33333%;
  }
  /* line 50, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-pull-11 {
    right: 91.66667%;
  }
  /* line 50, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-pull-12 {
    right: 100%;
  }
  /* line 45, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-push-0 {
    left: auto;
  }
  /* line 40, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-push-1 {
    left: 8.33333%;
  }
  /* line 40, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-push-2 {
    left: 16.66667%;
  }
  /* line 40, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-push-3 {
    left: 25%;
  }
  /* line 40, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-push-4 {
    left: 33.33333%;
  }
  /* line 40, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-push-5 {
    left: 41.66667%;
  }
  /* line 40, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-push-6 {
    left: 50%;
  }
  /* line 40, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-push-7 {
    left: 58.33333%;
  }
  /* line 40, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-push-8 {
    left: 66.66667%;
  }
  /* line 40, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-push-9 {
    left: 75%;
  }
  /* line 40, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-push-10 {
    left: 83.33333%;
  }
  /* line 40, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-push-11 {
    left: 91.66667%;
  }
  /* line 40, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-push-12 {
    left: 100%;
  }
  /* line 60, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-offset-0 {
    margin-left: 0%;
  }
  /* line 60, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-offset-1 {
    margin-left: 8.33333%;
  }
  /* line 60, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-offset-2 {
    margin-left: 16.66667%;
  }
  /* line 60, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-offset-3 {
    margin-left: 25%;
  }
  /* line 60, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-offset-4 {
    margin-left: 33.33333%;
  }
  /* line 60, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-offset-5 {
    margin-left: 41.66667%;
  }
  /* line 60, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-offset-6 {
    margin-left: 50%;
  }
  /* line 60, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-offset-7 {
    margin-left: 58.33333%;
  }
  /* line 60, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-offset-8 {
    margin-left: 66.66667%;
  }
  /* line 60, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-offset-9 {
    margin-left: 75%;
  }
  /* line 60, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-offset-10 {
    margin-left: 83.33333%;
  }
  /* line 60, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-offset-11 {
    margin-left: 91.66667%;
  }
  /* line 60, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-offset-12 {
    margin-left: 100%;
  }
}

@media (min-width: 992px) {
  /* line 27, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12 {
    float: left;
  }
  /* line 35, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-1 {
    width: 8.33333%;
  }
  /* line 35, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-2 {
    width: 16.66667%;
  }
  /* line 35, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-3 {
    width: 25%;
  }
  /* line 35, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-4 {
    width: 33.33333%;
  }
  /* line 35, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-5 {
    width: 41.66667%;
  }
  /* line 35, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-6 {
    width: 50%;
  }
  /* line 35, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-7 {
    width: 58.33333%;
  }
  /* line 35, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-8 {
    width: 66.66667%;
  }
  /* line 35, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-9 {
    width: 75%;
  }
  /* line 35, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-10 {
    width: 83.33333%;
  }
  /* line 35, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-11 {
    width: 91.66667%;
  }
  /* line 35, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-12 {
    width: 100%;
  }
  /* line 55, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-pull-0 {
    right: auto;
  }
  /* line 50, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-pull-1 {
    right: 8.33333%;
  }
  /* line 50, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-pull-2 {
    right: 16.66667%;
  }
  /* line 50, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-pull-3 {
    right: 25%;
  }
  /* line 50, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-pull-4 {
    right: 33.33333%;
  }
  /* line 50, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-pull-5 {
    right: 41.66667%;
  }
  /* line 50, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-pull-6 {
    right: 50%;
  }
  /* line 50, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-pull-7 {
    right: 58.33333%;
  }
  /* line 50, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-pull-8 {
    right: 66.66667%;
  }
  /* line 50, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-pull-9 {
    right: 75%;
  }
  /* line 50, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-pull-10 {
    right: 83.33333%;
  }
  /* line 50, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-pull-11 {
    right: 91.66667%;
  }
  /* line 50, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-pull-12 {
    right: 100%;
  }
  /* line 45, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-push-0 {
    left: auto;
  }
  /* line 40, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-push-1 {
    left: 8.33333%;
  }
  /* line 40, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-push-2 {
    left: 16.66667%;
  }
  /* line 40, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-push-3 {
    left: 25%;
  }
  /* line 40, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-push-4 {
    left: 33.33333%;
  }
  /* line 40, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-push-5 {
    left: 41.66667%;
  }
  /* line 40, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-push-6 {
    left: 50%;
  }
  /* line 40, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-push-7 {
    left: 58.33333%;
  }
  /* line 40, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-push-8 {
    left: 66.66667%;
  }
  /* line 40, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-push-9 {
    left: 75%;
  }
  /* line 40, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-push-10 {
    left: 83.33333%;
  }
  /* line 40, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-push-11 {
    left: 91.66667%;
  }
  /* line 40, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-push-12 {
    left: 100%;
  }
  /* line 60, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-offset-0 {
    margin-left: 0%;
  }
  /* line 60, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-offset-1 {
    margin-left: 8.33333%;
  }
  /* line 60, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-offset-2 {
    margin-left: 16.66667%;
  }
  /* line 60, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-offset-3 {
    margin-left: 25%;
  }
  /* line 60, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-offset-4 {
    margin-left: 33.33333%;
  }
  /* line 60, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-offset-5 {
    margin-left: 41.66667%;
  }
  /* line 60, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-offset-6 {
    margin-left: 50%;
  }
  /* line 60, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-offset-7 {
    margin-left: 58.33333%;
  }
  /* line 60, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-offset-8 {
    margin-left: 66.66667%;
  }
  /* line 60, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-offset-9 {
    margin-left: 75%;
  }
  /* line 60, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-offset-10 {
    margin-left: 83.33333%;
  }
  /* line 60, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-offset-11 {
    margin-left: 91.66667%;
  }
  /* line 60, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-offset-12 {
    margin-left: 100%;
  }
}

@media (min-width: 1200px) {
  /* line 27, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12 {
    float: left;
  }
  /* line 35, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-1 {
    width: 8.33333%;
  }
  /* line 35, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-2 {
    width: 16.66667%;
  }
  /* line 35, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-3 {
    width: 25%;
  }
  /* line 35, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-4 {
    width: 33.33333%;
  }
  /* line 35, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-5 {
    width: 41.66667%;
  }
  /* line 35, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-6 {
    width: 50%;
  }
  /* line 35, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-7 {
    width: 58.33333%;
  }
  /* line 35, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-8 {
    width: 66.66667%;
  }
  /* line 35, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-9 {
    width: 75%;
  }
  /* line 35, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-10 {
    width: 83.33333%;
  }
  /* line 35, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-11 {
    width: 91.66667%;
  }
  /* line 35, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-12 {
    width: 100%;
  }
  /* line 55, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-pull-0 {
    right: auto;
  }
  /* line 50, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-pull-1 {
    right: 8.33333%;
  }
  /* line 50, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-pull-2 {
    right: 16.66667%;
  }
  /* line 50, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-pull-3 {
    right: 25%;
  }
  /* line 50, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-pull-4 {
    right: 33.33333%;
  }
  /* line 50, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-pull-5 {
    right: 41.66667%;
  }
  /* line 50, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-pull-6 {
    right: 50%;
  }
  /* line 50, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-pull-7 {
    right: 58.33333%;
  }
  /* line 50, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-pull-8 {
    right: 66.66667%;
  }
  /* line 50, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-pull-9 {
    right: 75%;
  }
  /* line 50, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-pull-10 {
    right: 83.33333%;
  }
  /* line 50, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-pull-11 {
    right: 91.66667%;
  }
  /* line 50, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-pull-12 {
    right: 100%;
  }
  /* line 45, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-push-0 {
    left: auto;
  }
  /* line 40, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-push-1 {
    left: 8.33333%;
  }
  /* line 40, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-push-2 {
    left: 16.66667%;
  }
  /* line 40, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-push-3 {
    left: 25%;
  }
  /* line 40, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-push-4 {
    left: 33.33333%;
  }
  /* line 40, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-push-5 {
    left: 41.66667%;
  }
  /* line 40, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-push-6 {
    left: 50%;
  }
  /* line 40, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-push-7 {
    left: 58.33333%;
  }
  /* line 40, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-push-8 {
    left: 66.66667%;
  }
  /* line 40, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-push-9 {
    left: 75%;
  }
  /* line 40, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-push-10 {
    left: 83.33333%;
  }
  /* line 40, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-push-11 {
    left: 91.66667%;
  }
  /* line 40, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-push-12 {
    left: 100%;
  }
  /* line 60, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-offset-0 {
    margin-left: 0%;
  }
  /* line 60, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-offset-1 {
    margin-left: 8.33333%;
  }
  /* line 60, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-offset-2 {
    margin-left: 16.66667%;
  }
  /* line 60, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-offset-3 {
    margin-left: 25%;
  }
  /* line 60, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-offset-4 {
    margin-left: 33.33333%;
  }
  /* line 60, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-offset-5 {
    margin-left: 41.66667%;
  }
  /* line 60, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-offset-6 {
    margin-left: 50%;
  }
  /* line 60, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-offset-7 {
    margin-left: 58.33333%;
  }
  /* line 60, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-offset-8 {
    margin-left: 66.66667%;
  }
  /* line 60, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-offset-9 {
    margin-left: 75%;
  }
  /* line 60, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-offset-10 {
    margin-left: 83.33333%;
  }
  /* line 60, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-offset-11 {
    margin-left: 91.66667%;
  }
  /* line 60, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-offset-12 {
    margin-left: 100%;
  }
}

/* line 12, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/scss/boostrap_overrides/_grid.scss */
.container, .section-container {
  margin-right: auto;
  margin-left: auto;
  padding-left: 4px;
  padding-right: 4px;
}

@media (min-width: 768px) {
  /* line 12, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/scss/boostrap_overrides/_grid.scss */
  .container, .section-container {
    padding-left: 5px;
    padding-right: 5px;
  }
}

@media (min-width: 992px) {
  /* line 12, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/scss/boostrap_overrides/_grid.scss */
  .container, .section-container {
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media (min-width: 1200px) {
  /* line 12, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/scss/boostrap_overrides/_grid.scss */
  .container, .section-container {
    padding-left: 15px;
    padding-right: 15px;
  }
}

/* line 14, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_clearfix.scss */
.container:before, .container:after, .section-container:before, .section-container:after {
  content: " ";
  display: table;
}

/* line 19, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_clearfix.scss */
.container:after, .section-container:after {
  clear: both;
}

@media (min-width: 768px) {
  /* line 12, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/scss/boostrap_overrides/_grid.scss */
  .container, .section-container {
    width: 728px;
  }
}

@media (min-width: 992px) {
  /* line 12, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/scss/boostrap_overrides/_grid.scss */
  .container, .section-container {
    width: 948px;
  }
}

@media (min-width: 1200px) {
  /* line 12, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/scss/boostrap_overrides/_grid.scss */
  .container, .section-container {
    width: 1148px;
  }
}

@media (min-width: 1300px) {
  /* line 12, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/scss/boostrap_overrides/_grid.scss */
  .container, .section-container {
    width: 1270px;
  }
}

@media (min-width: 1400px) {
  /* line 12, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/scss/boostrap_overrides/_grid.scss */
  .container, .section-container {
    width: 1370px;
  }
}

/* line 38, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/scss/boostrap_overrides/_grid.scss */
.container-fluid {
  margin-right: auto;
  margin-left: auto;
  padding-left: 4px;
  padding-right: 4px;
}

@media (min-width: 768px) {
  /* line 38, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/scss/boostrap_overrides/_grid.scss */
  .container-fluid {
    padding-left: 5px;
    padding-right: 5px;
  }
}

@media (min-width: 992px) {
  /* line 38, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/scss/boostrap_overrides/_grid.scss */
  .container-fluid {
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media (min-width: 1200px) {
  /* line 38, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/scss/boostrap_overrides/_grid.scss */
  .container-fluid {
    padding-left: 15px;
    padding-right: 15px;
  }
}

/* line 14, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_clearfix.scss */
.container-fluid:before, .container-fluid:after {
  content: " ";
  display: table;
}

/* line 19, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_clearfix.scss */
.container-fluid:after {
  clear: both;
}

/* line 47, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/scss/boostrap_overrides/_grid.scss */
.row {
  margin-left: -4px;
  margin-right: -4px;
}

/* line 14, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_clearfix.scss */
.row:before, .row:after {
  content: " ";
  display: table;
}

/* line 19, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_clearfix.scss */
.row:after {
  clear: both;
}

@media (min-width: 768px) {
  /* line 47, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/scss/boostrap_overrides/_grid.scss */
  .row {
    margin-left: -5px;
    margin-right: -5px;
  }
}

@media (min-width: 992px) {
  /* line 47, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/scss/boostrap_overrides/_grid.scss */
  .row {
    margin-left: -10px;
    margin-right: -10px;
  }
}

@media (min-width: 1200px) {
  /* line 47, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/scss/boostrap_overrides/_grid.scss */
  .row {
    margin-left: -15px;
    margin-right: -15px;
  }
}

/* line 16, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/scss/boostrap_overrides/mixins/_grid-framework.scss */
.col-xs-1, .col-sm-1, .col-md-1, .col-lg-1, .col-xs-2, .col-sm-2, .col-md-2, .col-lg-2, .col-xs-3, .col-sm-3, .col-md-3, .col-lg-3, .col-xs-4, .col-sm-4, .col-md-4, .col-lg-4, .col-xs-5, .col-sm-5, .col-md-5, .col-lg-5, .col-xs-6, .col-sm-6, .col-md-6, .col-lg-6, .col-xs-7, .col-sm-7, .col-md-7, .col-lg-7, .col-xs-8, .col-sm-8, .col-md-8, .col-lg-8, .col-xs-9, .col-sm-9, .col-md-9, .col-lg-9, .col-xs-10, .col-sm-10, .col-md-10, .col-lg-10, .col-xs-11, .col-sm-11, .col-md-11, .col-lg-11, .col-xs-12, .col-sm-12, .col-md-12, .col-lg-12 {
  position: relative;
  min-height: 1px;
  padding-left: 4px;
  padding-right: 4px;
}

@media (min-width: 768px) {
  /* line 16, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/scss/boostrap_overrides/mixins/_grid-framework.scss */
  .col-xs-1, .col-sm-1, .col-md-1, .col-lg-1, .col-xs-2, .col-sm-2, .col-md-2, .col-lg-2, .col-xs-3, .col-sm-3, .col-md-3, .col-lg-3, .col-xs-4, .col-sm-4, .col-md-4, .col-lg-4, .col-xs-5, .col-sm-5, .col-md-5, .col-lg-5, .col-xs-6, .col-sm-6, .col-md-6, .col-lg-6, .col-xs-7, .col-sm-7, .col-md-7, .col-lg-7, .col-xs-8, .col-sm-8, .col-md-8, .col-lg-8, .col-xs-9, .col-sm-9, .col-md-9, .col-lg-9, .col-xs-10, .col-sm-10, .col-md-10, .col-lg-10, .col-xs-11, .col-sm-11, .col-md-11, .col-lg-11, .col-xs-12, .col-sm-12, .col-md-12, .col-lg-12 {
    padding-left: 5px;
    padding-right: 5px;
  }
}

@media (min-width: 992px) {
  /* line 16, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/scss/boostrap_overrides/mixins/_grid-framework.scss */
  .col-xs-1, .col-sm-1, .col-md-1, .col-lg-1, .col-xs-2, .col-sm-2, .col-md-2, .col-lg-2, .col-xs-3, .col-sm-3, .col-md-3, .col-lg-3, .col-xs-4, .col-sm-4, .col-md-4, .col-lg-4, .col-xs-5, .col-sm-5, .col-md-5, .col-lg-5, .col-xs-6, .col-sm-6, .col-md-6, .col-lg-6, .col-xs-7, .col-sm-7, .col-md-7, .col-lg-7, .col-xs-8, .col-sm-8, .col-md-8, .col-lg-8, .col-xs-9, .col-sm-9, .col-md-9, .col-lg-9, .col-xs-10, .col-sm-10, .col-md-10, .col-lg-10, .col-xs-11, .col-sm-11, .col-md-11, .col-lg-11, .col-xs-12, .col-sm-12, .col-md-12, .col-lg-12 {
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media (min-width: 1200px) {
  /* line 16, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/scss/boostrap_overrides/mixins/_grid-framework.scss */
  .col-xs-1, .col-sm-1, .col-md-1, .col-lg-1, .col-xs-2, .col-sm-2, .col-md-2, .col-lg-2, .col-xs-3, .col-sm-3, .col-md-3, .col-lg-3, .col-xs-4, .col-sm-4, .col-md-4, .col-lg-4, .col-xs-5, .col-sm-5, .col-md-5, .col-lg-5, .col-xs-6, .col-sm-6, .col-md-6, .col-lg-6, .col-xs-7, .col-sm-7, .col-md-7, .col-lg-7, .col-xs-8, .col-sm-8, .col-md-8, .col-lg-8, .col-xs-9, .col-sm-9, .col-md-9, .col-lg-9, .col-xs-10, .col-sm-10, .col-md-10, .col-lg-10, .col-xs-11, .col-sm-11, .col-md-11, .col-lg-11, .col-xs-12, .col-sm-12, .col-md-12, .col-lg-12 {
    padding-left: 15px;
    padding-right: 15px;
  }
}

/* line 27, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-1, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9, .col-xs-10, .col-xs-11, .col-xs-12 {
  float: left;
}

/* line 35, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-1 {
  width: 8.33333%;
}

/* line 35, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-2 {
  width: 16.66667%;
}

/* line 35, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-3 {
  width: 25%;
}

/* line 35, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-4 {
  width: 33.33333%;
}

/* line 35, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-5 {
  width: 41.66667%;
}

/* line 35, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-6 {
  width: 50%;
}

/* line 35, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-7 {
  width: 58.33333%;
}

/* line 35, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-8 {
  width: 66.66667%;
}

/* line 35, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-9 {
  width: 75%;
}

/* line 35, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-10 {
  width: 83.33333%;
}

/* line 35, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-11 {
  width: 91.66667%;
}

/* line 35, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-12 {
  width: 100%;
}

/* line 55, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-pull-0 {
  right: auto;
}

/* line 50, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-pull-1 {
  right: 8.33333%;
}

/* line 50, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-pull-2 {
  right: 16.66667%;
}

/* line 50, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-pull-3 {
  right: 25%;
}

/* line 50, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-pull-4 {
  right: 33.33333%;
}

/* line 50, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-pull-5 {
  right: 41.66667%;
}

/* line 50, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-pull-6 {
  right: 50%;
}

/* line 50, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-pull-7 {
  right: 58.33333%;
}

/* line 50, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-pull-8 {
  right: 66.66667%;
}

/* line 50, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-pull-9 {
  right: 75%;
}

/* line 50, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-pull-10 {
  right: 83.33333%;
}

/* line 50, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-pull-11 {
  right: 91.66667%;
}

/* line 50, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-pull-12 {
  right: 100%;
}

/* line 45, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-push-0 {
  left: auto;
}

/* line 40, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-push-1 {
  left: 8.33333%;
}

/* line 40, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-push-2 {
  left: 16.66667%;
}

/* line 40, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-push-3 {
  left: 25%;
}

/* line 40, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-push-4 {
  left: 33.33333%;
}

/* line 40, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-push-5 {
  left: 41.66667%;
}

/* line 40, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-push-6 {
  left: 50%;
}

/* line 40, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-push-7 {
  left: 58.33333%;
}

/* line 40, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-push-8 {
  left: 66.66667%;
}

/* line 40, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-push-9 {
  left: 75%;
}

/* line 40, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-push-10 {
  left: 83.33333%;
}

/* line 40, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-push-11 {
  left: 91.66667%;
}

/* line 40, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-push-12 {
  left: 100%;
}

/* line 60, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-offset-0 {
  margin-left: 0%;
}

/* line 60, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-offset-1 {
  margin-left: 8.33333%;
}

/* line 60, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-offset-2 {
  margin-left: 16.66667%;
}

/* line 60, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-offset-3 {
  margin-left: 25%;
}

/* line 60, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-offset-4 {
  margin-left: 33.33333%;
}

/* line 60, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-offset-5 {
  margin-left: 41.66667%;
}

/* line 60, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-offset-6 {
  margin-left: 50%;
}

/* line 60, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-offset-7 {
  margin-left: 58.33333%;
}

/* line 60, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-offset-8 {
  margin-left: 66.66667%;
}

/* line 60, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-offset-9 {
  margin-left: 75%;
}

/* line 60, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-offset-10 {
  margin-left: 83.33333%;
}

/* line 60, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-offset-11 {
  margin-left: 91.66667%;
}

/* line 60, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
.col-xs-offset-12 {
  margin-left: 100%;
}

@media (min-width: 768px) {
  /* line 27, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12 {
    float: left;
  }
  /* line 35, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-1 {
    width: 8.33333%;
  }
  /* line 35, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-2 {
    width: 16.66667%;
  }
  /* line 35, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-3 {
    width: 25%;
  }
  /* line 35, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-4 {
    width: 33.33333%;
  }
  /* line 35, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-5 {
    width: 41.66667%;
  }
  /* line 35, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-6 {
    width: 50%;
  }
  /* line 35, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-7 {
    width: 58.33333%;
  }
  /* line 35, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-8 {
    width: 66.66667%;
  }
  /* line 35, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-9 {
    width: 75%;
  }
  /* line 35, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-10 {
    width: 83.33333%;
  }
  /* line 35, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-11 {
    width: 91.66667%;
  }
  /* line 35, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-12 {
    width: 100%;
  }
  /* line 55, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-pull-0 {
    right: auto;
  }
  /* line 50, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-pull-1 {
    right: 8.33333%;
  }
  /* line 50, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-pull-2 {
    right: 16.66667%;
  }
  /* line 50, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-pull-3 {
    right: 25%;
  }
  /* line 50, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-pull-4 {
    right: 33.33333%;
  }
  /* line 50, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-pull-5 {
    right: 41.66667%;
  }
  /* line 50, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-pull-6 {
    right: 50%;
  }
  /* line 50, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-pull-7 {
    right: 58.33333%;
  }
  /* line 50, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-pull-8 {
    right: 66.66667%;
  }
  /* line 50, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-pull-9 {
    right: 75%;
  }
  /* line 50, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-pull-10 {
    right: 83.33333%;
  }
  /* line 50, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-pull-11 {
    right: 91.66667%;
  }
  /* line 50, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-pull-12 {
    right: 100%;
  }
  /* line 45, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-push-0 {
    left: auto;
  }
  /* line 40, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-push-1 {
    left: 8.33333%;
  }
  /* line 40, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-push-2 {
    left: 16.66667%;
  }
  /* line 40, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-push-3 {
    left: 25%;
  }
  /* line 40, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-push-4 {
    left: 33.33333%;
  }
  /* line 40, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-push-5 {
    left: 41.66667%;
  }
  /* line 40, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-push-6 {
    left: 50%;
  }
  /* line 40, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-push-7 {
    left: 58.33333%;
  }
  /* line 40, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-push-8 {
    left: 66.66667%;
  }
  /* line 40, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-push-9 {
    left: 75%;
  }
  /* line 40, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-push-10 {
    left: 83.33333%;
  }
  /* line 40, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-push-11 {
    left: 91.66667%;
  }
  /* line 40, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-push-12 {
    left: 100%;
  }
  /* line 60, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-offset-0 {
    margin-left: 0%;
  }
  /* line 60, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-offset-1 {
    margin-left: 8.33333%;
  }
  /* line 60, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-offset-2 {
    margin-left: 16.66667%;
  }
  /* line 60, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-offset-3 {
    margin-left: 25%;
  }
  /* line 60, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-offset-4 {
    margin-left: 33.33333%;
  }
  /* line 60, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-offset-5 {
    margin-left: 41.66667%;
  }
  /* line 60, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-offset-6 {
    margin-left: 50%;
  }
  /* line 60, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-offset-7 {
    margin-left: 58.33333%;
  }
  /* line 60, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-offset-8 {
    margin-left: 66.66667%;
  }
  /* line 60, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-offset-9 {
    margin-left: 75%;
  }
  /* line 60, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-offset-10 {
    margin-left: 83.33333%;
  }
  /* line 60, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-offset-11 {
    margin-left: 91.66667%;
  }
  /* line 60, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-sm-offset-12 {
    margin-left: 100%;
  }
}

@media (min-width: 992px) {
  /* line 27, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12 {
    float: left;
  }
  /* line 35, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-1 {
    width: 8.33333%;
  }
  /* line 35, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-2 {
    width: 16.66667%;
  }
  /* line 35, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-3 {
    width: 25%;
  }
  /* line 35, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-4 {
    width: 33.33333%;
  }
  /* line 35, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-5 {
    width: 41.66667%;
  }
  /* line 35, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-6 {
    width: 50%;
  }
  /* line 35, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-7 {
    width: 58.33333%;
  }
  /* line 35, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-8 {
    width: 66.66667%;
  }
  /* line 35, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-9 {
    width: 75%;
  }
  /* line 35, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-10 {
    width: 83.33333%;
  }
  /* line 35, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-11 {
    width: 91.66667%;
  }
  /* line 35, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-12 {
    width: 100%;
  }
  /* line 55, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-pull-0 {
    right: auto;
  }
  /* line 50, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-pull-1 {
    right: 8.33333%;
  }
  /* line 50, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-pull-2 {
    right: 16.66667%;
  }
  /* line 50, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-pull-3 {
    right: 25%;
  }
  /* line 50, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-pull-4 {
    right: 33.33333%;
  }
  /* line 50, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-pull-5 {
    right: 41.66667%;
  }
  /* line 50, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-pull-6 {
    right: 50%;
  }
  /* line 50, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-pull-7 {
    right: 58.33333%;
  }
  /* line 50, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-pull-8 {
    right: 66.66667%;
  }
  /* line 50, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-pull-9 {
    right: 75%;
  }
  /* line 50, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-pull-10 {
    right: 83.33333%;
  }
  /* line 50, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-pull-11 {
    right: 91.66667%;
  }
  /* line 50, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-pull-12 {
    right: 100%;
  }
  /* line 45, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-push-0 {
    left: auto;
  }
  /* line 40, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-push-1 {
    left: 8.33333%;
  }
  /* line 40, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-push-2 {
    left: 16.66667%;
  }
  /* line 40, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-push-3 {
    left: 25%;
  }
  /* line 40, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-push-4 {
    left: 33.33333%;
  }
  /* line 40, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-push-5 {
    left: 41.66667%;
  }
  /* line 40, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-push-6 {
    left: 50%;
  }
  /* line 40, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-push-7 {
    left: 58.33333%;
  }
  /* line 40, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-push-8 {
    left: 66.66667%;
  }
  /* line 40, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-push-9 {
    left: 75%;
  }
  /* line 40, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-push-10 {
    left: 83.33333%;
  }
  /* line 40, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-push-11 {
    left: 91.66667%;
  }
  /* line 40, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-push-12 {
    left: 100%;
  }
  /* line 60, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-offset-0 {
    margin-left: 0%;
  }
  /* line 60, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-offset-1 {
    margin-left: 8.33333%;
  }
  /* line 60, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-offset-2 {
    margin-left: 16.66667%;
  }
  /* line 60, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-offset-3 {
    margin-left: 25%;
  }
  /* line 60, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-offset-4 {
    margin-left: 33.33333%;
  }
  /* line 60, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-offset-5 {
    margin-left: 41.66667%;
  }
  /* line 60, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-offset-6 {
    margin-left: 50%;
  }
  /* line 60, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-offset-7 {
    margin-left: 58.33333%;
  }
  /* line 60, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-offset-8 {
    margin-left: 66.66667%;
  }
  /* line 60, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-offset-9 {
    margin-left: 75%;
  }
  /* line 60, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-offset-10 {
    margin-left: 83.33333%;
  }
  /* line 60, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-offset-11 {
    margin-left: 91.66667%;
  }
  /* line 60, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-md-offset-12 {
    margin-left: 100%;
  }
}

@media (min-width: 1200px) {
  /* line 27, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12 {
    float: left;
  }
  /* line 35, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-1 {
    width: 8.33333%;
  }
  /* line 35, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-2 {
    width: 16.66667%;
  }
  /* line 35, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-3 {
    width: 25%;
  }
  /* line 35, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-4 {
    width: 33.33333%;
  }
  /* line 35, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-5 {
    width: 41.66667%;
  }
  /* line 35, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-6 {
    width: 50%;
  }
  /* line 35, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-7 {
    width: 58.33333%;
  }
  /* line 35, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-8 {
    width: 66.66667%;
  }
  /* line 35, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-9 {
    width: 75%;
  }
  /* line 35, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-10 {
    width: 83.33333%;
  }
  /* line 35, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-11 {
    width: 91.66667%;
  }
  /* line 35, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-12 {
    width: 100%;
  }
  /* line 55, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-pull-0 {
    right: auto;
  }
  /* line 50, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-pull-1 {
    right: 8.33333%;
  }
  /* line 50, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-pull-2 {
    right: 16.66667%;
  }
  /* line 50, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-pull-3 {
    right: 25%;
  }
  /* line 50, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-pull-4 {
    right: 33.33333%;
  }
  /* line 50, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-pull-5 {
    right: 41.66667%;
  }
  /* line 50, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-pull-6 {
    right: 50%;
  }
  /* line 50, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-pull-7 {
    right: 58.33333%;
  }
  /* line 50, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-pull-8 {
    right: 66.66667%;
  }
  /* line 50, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-pull-9 {
    right: 75%;
  }
  /* line 50, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-pull-10 {
    right: 83.33333%;
  }
  /* line 50, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-pull-11 {
    right: 91.66667%;
  }
  /* line 50, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-pull-12 {
    right: 100%;
  }
  /* line 45, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-push-0 {
    left: auto;
  }
  /* line 40, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-push-1 {
    left: 8.33333%;
  }
  /* line 40, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-push-2 {
    left: 16.66667%;
  }
  /* line 40, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-push-3 {
    left: 25%;
  }
  /* line 40, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-push-4 {
    left: 33.33333%;
  }
  /* line 40, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-push-5 {
    left: 41.66667%;
  }
  /* line 40, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-push-6 {
    left: 50%;
  }
  /* line 40, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-push-7 {
    left: 58.33333%;
  }
  /* line 40, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-push-8 {
    left: 66.66667%;
  }
  /* line 40, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-push-9 {
    left: 75%;
  }
  /* line 40, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-push-10 {
    left: 83.33333%;
  }
  /* line 40, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-push-11 {
    left: 91.66667%;
  }
  /* line 40, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-push-12 {
    left: 100%;
  }
  /* line 60, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-offset-0 {
    margin-left: 0%;
  }
  /* line 60, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-offset-1 {
    margin-left: 8.33333%;
  }
  /* line 60, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-offset-2 {
    margin-left: 16.66667%;
  }
  /* line 60, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-offset-3 {
    margin-left: 25%;
  }
  /* line 60, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-offset-4 {
    margin-left: 33.33333%;
  }
  /* line 60, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-offset-5 {
    margin-left: 41.66667%;
  }
  /* line 60, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-offset-6 {
    margin-left: 50%;
  }
  /* line 60, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-offset-7 {
    margin-left: 58.33333%;
  }
  /* line 60, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-offset-8 {
    margin-left: 66.66667%;
  }
  /* line 60, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-offset-9 {
    margin-left: 75%;
  }
  /* line 60, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-offset-10 {
    margin-left: 83.33333%;
  }
  /* line 60, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-offset-11 {
    margin-left: 91.66667%;
  }
  /* line 60, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss */
  .col-lg-offset-12 {
    margin-left: 100%;
  }
}

/*
 * A partial implementation of the Ruby list functions from Compass:
 * https://github.com/Compass/compass/blob/stable/lib/compass/sass_extensions/functions/lists.rb
 */
/*
 * A partial implementation of the Ruby constants functions from Compass:
 * https://github.com/Compass/compass/blob/stable/lib/compass/sass_extensions/functions/constants.rb
 */
/*
 * A partial implementation of the Ruby display functions from Compass:
 * https://github.com/Compass/compass/blob/stable/core/lib/compass/core/sass_extensions/functions/display.rb
 */
/* line 3, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/scss/site_overrides/_site_overrides.scss */
.og-context-node-95 .globalCTAs__cta__image {
  border-radius: 0%;
}

/* line 10, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/scss/site_overrides/_site_overrides.scss */
.deepBayFollow {
  margin-top: 1em;
  text-align: center;
}

/* line 13, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/scss/site_overrides/_site_overrides.scss */
.deepBayFollow a:after {
  content: none !important;
}

/* line 16, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/scss/site_overrides/_site_overrides.scss */
.deepBayFollow .viu-social__socialIcon {
  font-size: 40px;
  margin: 0 1em;
}

/* line 13, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/scss/site_overrides/_csi-site.scss */
.csi-site h1.title {
  color: #003B5C;
}

/* line 18, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/scss/site_overrides/_csi-site.scss */
.csi-site h2, .csi-site h3, .csi-site h4, .csi-site h5 {
  color: #0A838E;
}

/* line 22, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/scss/site_overrides/_csi-site.scss */
.csi-site .background-color-primary, .csi-site .page-section.background-color-primary {
  background-color: #1C7BBB;
  color: white;
}

/* line 26, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/scss/site_overrides/_csi-site.scss */
.csi-site .background-color-primary h1, .csi-site .background-color-primary h2, .csi-site .background-color-primary h3, .csi-site .background-color-primary h4, .csi-site .background-color-primary h5, .csi-site .page-section.background-color-primary h1, .csi-site .page-section.background-color-primary h2, .csi-site .page-section.background-color-primary h3, .csi-site .page-section.background-color-primary h4, .csi-site .page-section.background-color-primary h5 {
  color: white;
}

/* line 31, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/scss/site_overrides/_csi-site.scss */
.csi-site .background-color-secondary, .csi-site .page-section.background-color-secondary {
  background-color: #0A838E;
  color: white;
}

/* line 35, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/scss/site_overrides/_csi-site.scss */
.csi-site .background-color-secondary h1, .csi-site .background-color-secondary h2, .csi-site .background-color-secondary h3, .csi-site .background-color-secondary h4, .csi-site .background-color-secondary h5, .csi-site .page-section.background-color-secondary h1, .csi-site .page-section.background-color-secondary h2, .csi-site .page-section.background-color-secondary h3, .csi-site .page-section.background-color-secondary h4, .csi-site .page-section.background-color-secondary h5 {
  color: white;
}

/* line 40, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/scss/site_overrides/_csi-site.scss */
.csi-site .background-color-faint, .csi-site .page-section.background-color-faint {
  background-color: #e6f4f4;
  color: black;
}

/* line 48, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/scss/site_overrides/_csi-site.scss */
.csi-site .background-color-faint h2, .csi-site .background-color-faint h3, .csi-site .background-color-faint h4, .csi-site .background-color-faint h5, .csi-site .page-section.background-color-faint h2, .csi-site .page-section.background-color-faint h3, .csi-site .page-section.background-color-faint h4, .csi-site .page-section.background-color-faint h5 {
  color: #003B5C;
}

/* line 53, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/scss/site_overrides/_csi-site.scss */
.csi-site .background-color-tertiary, .csi-site .page-section.background-color-tertiary {
  background-color: #003B5C;
}

/* line 57, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/scss/site_overrides/_csi-site.scss */
.csi-site .page-section.page-section--color-3 h1, .csi-site .page-section.page-section--color-3 h2,
.csi-site .page-section.page-section--color-3 h3, .csi-site .page-section.page-section--color-3 h4,
.csi-site .page-section.page-section--color-3 h5, .csi-site .page-section.page-section--color-3 h6 {
  color: #0A838E;
}

/* line 66, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/scss/site_overrides/_csi-site.scss */
.csi-site.not-logged-in {
  padding-left: 0;
  padding-right: 0;
  padding-bottom: 0;
}

@media (min-width: 1200px) {
  /* line 66, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/scss/site_overrides/_csi-site.scss */
  .csi-site.not-logged-in {
    padding-top: 61px;
  }
}

/* line 74, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/scss/site_overrides/_csi-site.scss */
.csi-site.not-logged-in:before {
  border-left-width: 0;
  border-bottom-width: 0;
  border-right-width: 0;
}

@media (min-width: 1200px) {
  /* line 74, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/scss/site_overrides/_csi-site.scss */
  .csi-site.not-logged-in:before {
    border-top-width: 61px;
  }
}

@media (min-width: 1200px) {
  /* line 85, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/scss/site_overrides/_csi-site.scss */
  .csi-site .viu-audience-nav {
    padding: 18px 0;
    height: 61px;
  }
  /* line 89, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/scss/site_overrides/_csi-site.scss */
  .csi-site .viu-audience-nav__list li a {
    font-size: 25px;
    line-height: 25px;
    padding: 0 5em;
  }
}

/* line 96, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/scss/site_overrides/_csi-site.scss */
.csi-site .homepageHero__scroll {
  display: none;
}

/* line 100, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/scss/site_overrides/_csi-site.scss */
.csi-site .page-section--hero .page-section__content__inner {
  position: relative;
  float: left;
  width: 66.66667%;
  min-height: 1px;
  padding-left: 4px;
  padding-right: 4px;
  margin-left: 16.66667%;
  position: static;
}

/* line 106, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/scss/site_overrides/_csi-site.scss */
.csi-site .csi_logo {
  text-align: left;
  font-size: 1.8em;
  float: right;
}

@media (min-width: 768px) {
  /* line 106, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/scss/site_overrides/_csi-site.scss */
  .csi-site .csi_logo {
    font-size: 60px;
    line-height: 1em;
  }
}

@media (min-width: 992px) {
  /* line 106, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/scss/site_overrides/_csi-site.scss */
  .csi-site .csi_logo {
    font-size: 84px;
  }
}

/* line 119, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/scss/site_overrides/_csi-site.scss */
.csi-site .csi_logo__small {
  text-transform: uppercase;
  font-size: 1.2rem;
  line-height: 1.2rem;
  font-weight: normal;
  letter-spacing: .1em;
}

@media (min-width: 768px) {
  /* line 119, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/scss/site_overrides/_csi-site.scss */
  .csi-site .csi_logo__small {
    font-size: 25px;
    line-height: .8em;
  }
}

@media (min-width: 992px) {
  /* line 119, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/scss/site_overrides/_csi-site.scss */
  .csi-site .csi_logo__small {
    font-size: 38px;
  }
}

/* line 135, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/scss/site_overrides/_csi-site.scss */
.csi-site .side-apply-tab, .csi-site .side-search-tab {
  background: white;
  color: #1C7BBB;
}

/* line 141, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/scss/site_overrides/_csi-site.scss */
.csi-site .viuNavigation__toggle-menu, .csi-site #nav-viu-main {
  display: none;
}

/* line 145, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/scss/site_overrides/_csi-site.scss */
.csi-site .viuNavigation__menu-header {
  display: none;
}

/* line 149, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/scss/site_overrides/_csi-site.scss */
.csi-site .nav__csi_logo {
  margin-left: auto;
  margin-right: auto;
  width: 82%;
  max-width: 400px;
  color: white;
  font-size: 1.5em;
  line-height: 1em;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 40px;
}

@media (min-width: 768px) {
  /* line 149, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/scss/site_overrides/_csi-site.scss */
  .csi-site .nav__csi_logo {
    max-width: 470px;
  }
}

/* line 157, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/scss/site_overrides/_csi-site.scss */
.csi-site .nav__csi_logo a, .csi-site .nav__csi_logo a:hover {
  text-decoration: none;
  color: white;
}

/* line 163, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/scss/site_overrides/_csi-site.scss */
.csi-site .nav__csi_logo__small {
  font-size: .5em;
  line-height: 1em;
  font-weight: normal;
}

/* line 169, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/scss/site_overrides/_csi-site.scss */
.csi-site .globalCTAs__cta--has-image {
  text-align: left;
}

/* line 174, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/scss/site_overrides/_csi-site.scss */
.csi-site .globalCTAs__link {
  margin-left: 0;
  margin-right: 0;
}

/* line 179, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/scss/site_overrides/_csi-site.scss */
.csi-site .panel-pane.pane-page-content, .csi-site .panel-pane {
  padding-bottom: 0;
}

/* line 183, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/scss/site_overrides/_csi-site.scss */
.csi-site .viu-social a[href*="//"]:not([href*="viu.ca"]):after, .csi-site #nav-site a[href*="//"]:not([href*="viu.ca"]):after {
  display: none;
}

/* line 188, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/scss/site_overrides/_csi-site.scss */
.csi-site .page-section.page-section--color-1 a.cta-button, .csi-site .box-color-1 a.cta-button {
  color: #0a838e;
  background-color: white;
  border-color: white;
}

/* line 193, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/scss/site_overrides/_csi-site.scss */
.csi-site .page-section.page-section--color-1 a.cta-button:hover, .csi-site .box-color-1 a.cta-button:hover {
  color: white;
  background-color: transparent;
  border-color: white;
}

/* line 201, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/scss/site_overrides/_csi-site.scss */
.csiFooter {
  border-top-color: #0A838E;
  border-top-style: solid;
  border-top-width: 61px;
}

/* line 206, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/scss/site_overrides/_csi-site.scss */
.csiFooter .viuFooter__linkList__heading, .csiFooter .footer__linkList__a, .csiFooter .viuFooter__contact__heading, .csiFooter address {
  color: #003B5C !important;
}

/* line 210, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/scss/site_overrides/_csi-site.scss */
.csiFooter .footer__csi_logo {
  font-size: 2.1em;
  line-height: 1em;
  font-weight: bold;
  text-transform: uppercase;
}

/* line 217, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/scss/site_overrides/_csi-site.scss */
.csiFooter .footer__csi_logo__small {
  font-size: 1em;
  font-weight: bold;
  text-transform: uppercase;
}

/* line 223, /home/lee/apps/viu-research/sites/all/themes/viu_research_theme/scss/site_overrides/_csi-site.scss */
.csiFooter .footer__csi_button a {
  display: inline-block;
  color: white;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 0px;
}
