// flex CTAs square image override for Deep Bay.

.og-context-node-95 .globalCTAs__cta__image{
  border-radius: 0%;
}


// Custom styling for Deep bay homepage

.deepBayFollow{
  margin-top: 1em;
  text-align: center;
  a:after{
    content: none !important;
  }
  .viu-social__socialIcon{
    font-size: 40px;
    margin: 0 1em;
  }
}
@include breakpoint('md') {

}
