// Mixins
// -----------------------------------------------------------------------------

// Mixin to provide padding and width restrictions to main nav content
// Required to selectively leave some things full width (divider lines)
@mixin nav_contained{
  margin-left:auto;
  margin-right:auto;
  width:82%;
  max-width: 400px;
  @include breakpoint("sm"){
    max-width: 470px;
  }
}
